@import 'bootstrap/functions';

@import 'base/module';

@import 'bootstrap/bootstrap';

@import 'helpers/module';

@import 'core/module';

@import 'components/module';

@import 'pages/module';

@import 'vendor/module';



.newsLanding #companies-toolbar{
    display:none;
}
.current_hlodings .bg-col{
    opacity: 0.94;
    height:220px;
    min-height: -webkit-fill-available;
vertical-align: middle;
width: 100%;
max-width: 100% !important;
display: grid;
align-items: center;
justify-content: center;
}
.current_hlodings .bg-col img  {
vertical-align: middle;
object-fit: contain;
object-position: center;
width: 100%;
}
.current_hlodings .bg-col:hover{
opacity:0.7;
}
.companies_card:hover .bg-col {
opacity: 0.7;
}
.current_hlodings .companies_card{
    padding-right:0px;
    padding-left:0px;
    width: 100%;
    height:220px;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
}
.current_hlodings{
    padding-bottom:0;
}
.current_hlodings .intro-text p{
    margin-bottom:3rem;
}
.companies_card img {
position: absolute;
width: 300px;
left: 0;
right: 0;
margin: 0 auto;
bottom: 0;
top: 0;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
z-index:1;
}
.companies_card:last-child img{
    width:180px;
}
.business_legacy .mobile {
display:none;
}
.business_legacy .business_img img{
    width:100%;
    height:auto;
}
.business_legacy .business_img{
    display: flex;
align-items: center;
}
.desktop{
    column-count: 8;
display: flex;
align-items: center;
justify-content: center;
}
.desktop img{
width: 140px;
height: auto;
align-items: center;
padding: 10px;
}
@media screen and (max-width: 767px) {
.business_legacy .mobile {
display:block;
}
.business_legacy .desktop{
    display:none;
}
.business_legacy .carousel-item img{
    width: 250px;
height: 120px;
object-fit: contain;
}
.business_legacy .carousel-control-prev-icon{
    height: 35px;
    background-image:url('../img/Arrow-left.png');
}
.business_legacy .carousel-control-next-icon{
    background-image:url('../img/Arrow-rig.png');
    height: 35px;
}
.current_hlodings{
    padding-bottom:0;
}
}
@media screen and (max-width: 990px) {
.current_hlodings .bg-col img {
width: 100%;
padding: 20px;
}
.companies_card img{
    width:100%;
padding: 20px;
}
.companies_card:last-child img{
    width:100%;
}
}