* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border-top: $hr-border-width solid transparentize($white, 0.92);
}

.font-family-sans-serif {
  font-family: $font-family-sans-serif;
}

.font-family-serif {
  font-family: $font-family-serif;
}

// Alternative serif font - To be used as override for default 'Merriweather' font family.
.font-family-serif-alt {
  font-family: $font-family-serif-alt;
}

.break-word {
  word-break: break-word;
}

.p-small {
  font-size: $font-size-p-small;
}


// General rule for Font Awesome social media icons generated from $social-icons map.
.social-icon {
  display: inline-block;
  font-family: "Font Awesome 5 Brands";
  font-size: $font-size-base * 1.5;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

// Generate classes for each icon in $social-icons map.
@each $icon, $value in $social-icons {
  .social-icon--#{$icon} {
    &:before {
      content: "#{$value}";
    }
  }
}