// Overrides for BS base classes
.card {

  border-radius: 0;
  border: none;
  text-align: center;

  .card-img-top {
    border-radius: 0;
  }

  .card-body {
    padding: 0;
  }

}