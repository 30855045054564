.article__content {
    .row {
      &--paragraphVideo {
        .embed-responsive-4by3.embed-responsive--solo-video {
          @media screen and (min-width: breakpoint-min(md)) {
            &::before {
              padding-top: 56.25%;
            }
          }
        }
        .video-thumbnail {
          // <a> doesn't require an href, let's still give it a cursor though
          cursor: pointer;
        }
      }
    }
  }