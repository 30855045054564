/* responsive version for sizes, we don't generate xs because it is arleady generated by bootstrap */
@each $breakpoint in map-keys($grid-breakpoints) {
  @if $breakpoint != "xs" {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @each $prop, $abbrev in (width: w, height: h) {
        @each $size, $length in $sizes {
          .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        }
      }
    }
  }
}