// Brand Colors

$white:         #FFFFFF;
$gray:          #F4F5F6;
$midgray:       #D1D2D1;
$darkgray:      #B2B4B2;
$newsgray:      #E5E5E5;
$blue:          #1E4258;
$lightblue:     #009CA6;
$yellow:        #FFA300;
$sand:          #C6AA76;

$primary:       $blue;
$secondary:     $white;


// Theme Colors
$theme-colors: () !default;
$theme-colors: map_merge((
  "white":      $white,
  "gray":       $gray,
  "mid-gray":   $midgray,
  "dark-gray":  $darkgray,
  "blue":       $blue,
  "yellow":     $yellow,
  "lightblue":  $lightblue,
  "sand":       $sand
), $theme-colors);

// Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
        -1: -($spacer * .25),
        -2: -($spacer * .5),
        -3: -$spacer,
        -4: -($spacer * 1.5),
        -5: -($spacer * 3),
        -6: -($spacer * 6)
), $spacers);


// Letter spacing
$letter-spacing-base: 0.125rem;


// Content Block Spacing
$content-block-spacing: ($spacer * 3);


// Font family - Serif Alternative
$font-family-serif-alt: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


$font-size-base: 1rem;
$font-size-p-small: $font-size-base * 0.875;
$font-size-form-input: $font-size-base * 0.75;


// Global nav variables
$nav-dropdown-menu-l1-bg-color: $midgray;
$nav-dropdown-menu-l1-bg-color-mobile: $blue;
$nav-dropdown-menu-l2-bg-color: $midgray;
$nav-dropdown-menu-l2-bg-color-mobile: lighten($yellow, 7.5%);
$nav-dropdown-l2-caret-color: $yellow;
$nav-dropdown-l2-caret-color-mobile: $white;
$nav-node-active-accent-color: $yellow;
$nav-node-hover-accent-color: $yellow;
$nav-node-active-accent-color-alt: $yellow;
$nav-node-hover-accent-color-alt: $yellow;


// History 'section arrow' accent size
$section-triangle-accent-size: 15px;


// Social Media Icons
// Based on unicode values by fontawesome. See font awesome docs here... https://fontawesome.com/
$social-icons: ();
$social-icons: map-merge((
        "facebook": "\f39e",
        "instagram": "\f16d",
        "twitter": "\f099",
        "linkedin": "\f0e1",
        "googlePlus": "\f0d5",
        "youtube": "\f167",
        "vimeo": "\f27d"
), $social-icons);

// News
$news-filter-btn-height: 40px;