.form--dark {
  .field__errors,
  .field__error {
    color: $white;
  }
}

.field__errors {
  padding-left: 0;
  margin-top: map_get($spacers, 1);
  color: $danger;
  font-size: $font-size-form-input;
  list-style: none;
}

.form-alert {
  padding: map_get($spacers, 2) map_get($spacers, 3);
  margin-bottom: map_get($spacers, 4);
  border-width: 2px;
  border-style: solid;
  font-weight: $font-weight-normal;

  > ul {
    padding-left: 0;
    list-style: none;
  }

  > p:last-child,
  > ul:last-child {
    margin-bottom: 0;
  }
}

.field--has-errors {
  &:not(.custom-control) {
    border: 1px solid $danger !important;
  }

  /* Parsley places errors differently to Freeform JS, ensure the checkbox
  is styled correctly */
  .custom-control-label::before {
    border: 2px solid $danger !important;
  }
}

.custom-checkbox {
  .custom-control-input {
    &.field--has-errors {
      + .custom-control-label::before {
        border: 2px solid $danger;
      }
    }
  }
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

// Applied to submit button whilst AJAX is awaiting a response
button[type=submit] {
  &.ff-loading {
    pointer-events: none;
    color: transparent !important;

    // Reuse BS spinner CSS and tweak some values
    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 7px);
      left: calc(50% - 7px);
      display: inline-block;
      width: 14px;
      height: 14px;
      vertical-align: text-bottom;
      border: $spinner-border-width solid $yellow;
      border-right-color: transparent;
      border-radius: 50%;
      animation: spinner-border .75s linear infinite;
    }
  }
}