main.about-us {
  $team-figure-max-width: 265px;
  $collapsible-bio-width-md: calc(((100% / 2) - 265px) / 2);
  $collapsible-bio-width-lg: calc(((100% / 3) - 265px) / 2);

  .our-people {
    &__item {
      &:first-of-type {
        @media screen and (min-width: breakpoint-min(md)) {
          border-right: 2px solid transparentize($white, 0.5);
        }

        @media screen and (max-width: breakpoint-max(sm)) {
          padding-bottom: map_get($spacers, 5);
          border-bottom: 2px solid transparentize($white, 0.5);
        }
      }
    }
  }

  .collapsible-bio {
    margin: 0 auto;

    @media screen and (min-width: breakpoint-min(md)) {
      width: calc(100% - #{$collapsible-bio-width-md} - 30px);
    }

    @media screen and (min-width: breakpoint-min(lg)) {
      width: calc(100% - #{$collapsible-bio-width-lg} - 30px);
    }
  }
}

main.aboutUs--pageBuilder {
  div[id^=cardContainer] {
    max-width: 1400px;

    @media screen and (max-width: breakpoint-max(lg)) {
      max-width: 700px;
    }
  }
}