.article-card {

  &__wrapper {
    padding-top: calc(#{map_get($spacers, 4)});

    @media screen and (min-width: breakpoint-min(md)) {
      transition: 0.2s;

      &:hover {
        background-color: transparentize($blue, 0.8);
      }
    }
  }

  &__img {
    width: auto;
    height: 60px;
  }

  &__details {
    span {
      display: inline-block;
      position: relative;
      padding-right: calc(#{map_get($spacers, 2)} + 1px);
      font-family: $font-family-serif-alt;
      font-weight: $font-weight-normal;
      font-size: $font-size-p-small;
      text-transform: uppercase;
      line-height: 1;
      margin-right: map_get($spacers, 2);


      &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          right: -2px;
          top: 50%;
          height: 13px;
          width: 1px;
          background-color: map_get($theme-colors, "yellow");
          transform: translateY(-50%);
        }
      }

    } // End of span styling

    a {
      font-family: $font-family-serif-alt;
      font-weight: $font-weight-bold;
      font-size: $font-size-p-small;
      color: map_get($theme-colors, "primary");
    }
  } // End of __detail styling

  &__hr {
    width: 100%;
    background-color: map_get($theme-colors, "yellow");

    @media only screen and (max-width: breakpoint-max("sm")) {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      border-width: 2px;
    }
  }
}