.team-figures {
  width: 100%;

  figure.card {
    max-width: 200px;
    background-color: transparent;

    img {
      max-width: 200px;
      max-height: 200px;
    }

    figcaption.card-body {
      width: 100%;
      height: 50px; // Ensures consistent height if some names wrap to two lines, particularly on smaller devices.
      background-color: transparentize(#000, 0.5);
      border-bottom: 2px solid $yellow;
      font-family: $font-family-serif-alt;
      font-size: 0.875rem;
      letter-spacing: 0.5px;
    }
  }
}