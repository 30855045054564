main.site-map {
  background-image: url('../img/sundries-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .site-map-title {
    margin: 4rem 0;

    @media screen and (max-width: breakpoint-max(xs)) {
      margin: 3rem map_get($spacers, 3) 2rem map_get($spacers, 3);
    }

    h1 {
      font-family: $font-family-serif-alt;
      font-weight: 300;

      @media screen and (max-width: breakpoint-max(xs)) {
        font-size: 3rem;
      }
    }

    > .bullet {
      height: 13px;
      width: 40px;
      background-color: $yellow;

      &::after {
        height: 13px;
        width: 13px;
      }
    }
  }

  .site-map-copy {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .level-0 {
      @media only screen and (max-width: breakpoint-max(sm)) {
        font-size: 125%;
      }

      > li > .site-map-page {
        margin-bottom: map_get($spacers, 2);

        > .bullet {
          background-color: $blue;
        }
      }
    }

    .level-1 {
      padding-left: map_get($spacers, 5);

      .site-map-page {
        > .bullet {
          background-color: $blue;
        }
      }
    }

    .level-2 {
      padding-left: #{ map_get($spacers, 5) };

      .site-map-page {
        > .bullet {
          background-color: $blue;
        }
      }
    }

    // Adjust bullet styling to prevent pixel rounding issues with this smaller version
    .bullet {
      height: 6px;
      width: 27px;

      &::after {
        background-color: white;
        height: 7px;
        width: 10px;
        transform: skewX(-45deg);
      }
    }
  }
}