$our-companies-card-border-color: $midgray;

.company-cards__block {
  .card-wrapper {
    transition: 0.45s;
    background-color: transparentize($black, 0.6);

      &__hover {
        &--bg-dark:hover {
          background-color: transparentize($black, 0.4);
        }
      }
  }

  .card {
    background: none;
    transition: 0.3s;
    width: 240px;
    max-width: 240px;
    margin: 0 auto;

    .card-img {
      // SVG caused issues on IE, setting height explicitly fixes
      height: 81px;
    }

    .card-header {
      border-bottom: 5px solid $our-companies-card-border-color;
      width: 240px;
      max-width: 240px;
      //margin: auto;
      border-radius: 0;
      background-color: white;
    }

    &.card--hover {
      @include company-card-hover;
    }

    @media screen and (max-width: breakpoint-max(sm)) {
      &.card--collapse-hover {
        @include company-card-hover;
      }
    }

    @media screen and (min-width: breakpoint-min(md)) {
      .collapse {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
      }
    }

    &.active {
      .card-header {
        border-bottom-color: $yellow;
      }
    }
  }

  .mobile-collapse {
    border-bottom: 2px map_get($theme-colors, "yellow") solid;
  }

  .card-body {

    position: relative;
    color: white;

    @media only screen and (min-width: breakpoint-min(md)) {
      width: 240px;
      max-width: 240px;
    }

    .close {
      position: absolute;
      right: 0;
      color: #FFFFFF;

      @media screen and (min-width: breakpoint-min(md)) {
        display: none;
      }
    }

    .card-title {
      text-transform: uppercase;
      font-family: $font-family-sans-serif;
      font-weight: 600;
    }

    .card-description {
      p {
        font-size: $font-size-p-small;
      }
    }
  }

  .card-footer {
    border: 0;

    &--pending {
      font-size: $font-size-p-small;
    }
  }
}