// Z-levels
@mixin z-depth-0 {
  box-shadow: none !important;
}
@mixin z-depth-1{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
@mixin z-depth-1-half{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.17);
}
@mixin z-depth-2{
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.21);
}
@mixin z-depth-3{
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
@mixin z-depth-4{
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
@mixin z-depth-5{
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}


// Hover Shadows
.hoverable {
  transition: box-shadow .25s;
  box-shadow: 0;
}

.hoverable:hover {
  transition: box-shadow .25s;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.no-shadow {
  box-shadow: none !important;
}

// Z-levels as classes
.z-depth-0 {
  @include z-depth-0;
}

.z-depth-1 {
  @include z-depth-1;
}

.z-depth-1-half {
  @include z-depth-1-half;
}

.z-depth-2 {
  @include z-depth-2;
}

.z-depth-3 {
  @include z-depth-3;
}

.z-depth-4 {
  @include z-depth-4;
}

.z-depth-5 {
  @include z-depth-5;
}
