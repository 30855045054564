section.career-cards {

  .card {
    border-bottom: 8px solid;
    width: 375px;
    max-width: 375px;
    flex: 0 1 auto;

    // TODO: Check the width and margins changes with Dan
    @media screen and (min-width: breakpoint-min(sm)) and (max-width: breakpoint-max(lg)) {
      width: 550px;
      max-width: 550px;
    }

    .card-title {
      border-bottom: 1px $white solid;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}