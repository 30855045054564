#news-filters {
  background-color: map_get($theme-colors, "primary");

  // General class to apply a chevron to an input/select menu.
  .filter-chevron {
    &::before {
      content: "";
      position: absolute;
      right: 0.75rem;
      top: 50%;
      width: 10px;
      height: 10px;
      border: solid map_get($theme-colors, "lightblue");
      border-width: 2px 2px 0 0;
      transform-origin: 50% 0;
      transform: rotate(45deg) translateY(-50%);
      transition: 0.3s;
    }

    &[aria-expanded="true"] {
      &::before {
        transform-origin: 65% -12.5%;
        transform: rotate(135deg) translateY(-50%);
      }
    }
  }

  label,
  input {
    font-family: $font-family-serif-alt;
  }

  input[type="text"] {
    color: map_get($theme-colors, "primary");
    text-transform: none;
    font-family: $font-family-sans-serif;
    padding: 0 1rem;
    height: 2rem;

    &::placeholder {
      color: transparentize(map_get($theme-colors, "primary"), 0.3);
    }
  }

  .btn {
    min-width: 0 !important;
    max-width: 350px;
    padding: map_get($spacers, 2) map_get($spacers, 3);

    &.dropdown-toggle--filter {
      font-weight: 500;
      text-align: left;

      // Dropdown toggles have a default 'caret'. Remove this.
      &::after {
        display: none;
      }

      &:hover {
        background-color: $gray-300 !important;
        color: map_get($theme-colors, "primary") !important;

        span {
          &::after {
            display: block;
          }
        }
      }
    }

    .filter-chevron {
      // Ensure equal space for the chevron and no text overlapping
      padding-right: 1.75rem !important;

      @media screen and (max-width: breakpoint-max("md")) {
        padding-left: map_get($spacers, 3);
        text-align: left;
      }
    }
  }

  .company-select-wrapper {
    position: relative;
    max-width: 350px;
    margin: 0 auto;

    // Ensure equal space for the chevron and no text overlapping
    > .company-select {
      height: $news-filter-btn-height;
      border-radius: 0;
      color: $body-color !important;
      font-size: $font-size-base * 0.75;
      font-family: $font-family-serif-alt;
      font-weight: 500;
      text-transform: uppercase;
      @include z-depth-1;

      option {
        padding-right: 1.75rem !important;
      }
    }

    @media screen and (max-width: breakpoint-max("md")) {
      padding-left: map_get($spacers, 3);
      text-align: left;
    }

    &::before {
      content: "";
      position: absolute;
      right: 0.75rem;
      top: 50%;
      width: 10px;
      height: 10px;
      border: solid map_get($theme-colors, "lightblue");
      border-width: 2px 2px 0 0;
      transform-origin: 50% 0;
      transform: rotate(45deg) translateY(-50%);
      transition: 0.3s;
      z-index: 1000;
    }
  }

  .company-select {
    background: unset;
    background-color: $white;
  }

  .dropdown--filter {
    &.show {
      // On dropdown shown, rotate the dropdown chevron
      > .filter-chevron {
        background-color: $white;

        &::before {
          transform-origin: 65% -12.5%;
          transform: rotate(135deg) translateY(-50%);
        }
      }
    }
  }

  .filter-buttons {
    flex-wrap: wrap;
    flex: 1 1 auto;

    > .filter-button {
      justify-content: center;
      text-align: center;
      width: 20%;

      @media screen and (max-width: breakpoint-max("md")) {
        width: 100%;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;

        &:not(:first-of-type) {
          margin-top: map_get($spacers, 2);
        }

        // Necessary for IE11 fix
        button {
          display: inline-block;
        }
      }

      // Necessary for IE11 fix
      @media screen and (min-width: breakpoint-min("lg")) {
        &:not(:last-of-type) {
          padding-right: map_get($spacers, 2);
        }
      }

      button {
        height: $news-filter-btn-height;
      }

      &--order-by {
        margin-top: map_get($spacers, 1);
        width: max-content;

        @media screen and (max-width: breakpoint-max("lg")) {
          width: 100%;
        }
      }
    }

    .dropdown-menu {
      width: 16rem;

      @media screen and (max-width: breakpoint-max("sm")) {
        width: 100% !important;
        max-width: 350px;
      }

      &.daterange-dropdown {
        width: 100%;

        @media screen and (min-width: breakpoint-min("lg")) {
          width: 20rem;
        }

        #clear-dates {
          cursor: pointer;
          font-family: $font-family-serif-alt;
          font-size: $small-font-size;
          font-weight: 400;
          text-transform: initial;
        }

        #news-filters--daterange-calendar {
          .lightpick {
            &:not(.is-hidden) {
              display: flex;
              justify-content: center;
              padding: map_get($spacers, 2) 0 map_get($spacers, 2) 0 !important;
            }
          }
        }
      }
    }
  }

  .dropdown {
    .dropdown-menu {
      background-color: $newsgray !important;
      border-radius: 0;
      border: 0;
      color: map_get($theme-colors, "primary");

      @media screen and (max-width: breakpoint-max("md")) {
        width: 100%;
      }

      .form-custom-checkbox {
        margin-top: map_get($spacers, 2);

        .form-check-label {
          position: relative;
          padding-left: map_get($spacers, 4);
          font-family: $font-family-serif-alt;
          font-size: $font-size-p-small;
          text-transform: uppercase;

          &:hover {
            .checkmark {
              background-color: darken($newsgray, 10%);
            }
          }
        }

        // Custom checkbox indicator (hide standard input, use wrapping label to check and un-check)
        input[type="checkbox"],
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          // Custom indicator
          & ~ .checkmark {
            position: absolute;
            top: 4px;
            left: 0;
            height: 13px;
            width: 13px;
            background-color: $white;
            border: 1px solid map_get($theme-colors, "primary");

            &::after {
              content: "";
              position: absolute;
              display: none;
              left: 3px;
              top: 0;
              width: 5px;
              height: 9px;
              border: solid map_get($theme-colors, "primary");
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }

          &:checked ~ .checkmark {
            background-color: map_get($theme-colors, "blue");

            &::after {
              display: block;
              border-color: map_get($theme-colors, "white");
            }
          }
        }
      }

      .form-custom-radio {
        margin-top: map_get($spacers, 2);

        .form-check-label {
          position: relative;
          padding-left: 25px;
          font-family: $font-family-serif-alt;
          text-transform: uppercase;
          white-space: nowrap;

          &:hover {
            font-weight: $font-weight-bold;

            .fas {
              font-weight: $font-weight-bold;
            }
          }
        }

        input[type="radio"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ label {
            font-weight: $font-weight-bold;
            .fas {
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }
  }
}

// Article type order button/toggle
#order-by {
  font-family: $font-family-serif-alt;
  font-size: $font-size-p-small;

  #order-by-toggle {
    box-shadow: none;
    color: map_get($theme-colors, "dark-gray");

    &:hover {
      text-decoration: underline;
    }
  }
}

// Search results bar that display the number of results
.search-terms {
  background-color: transparentize($midgray, 0.5);
}

// Override BS default 'close' button styling
.reset-form {
  &--mobile {
    font-family: $font-family-serif-alt;
    font-size: $font-size-p-small;
  }

  .close {
    opacity: 1;
    text-shadow: none;
  }
}

#companies-toolbar {
  max-width: 350px;

  @media screen and (min-width: breakpoint-max("xs")) {
    max-width: 100%;
  }

  .collapse-toggle {
    font-size: $font-size-base;
    font-weight: 500;
  }

  .company-checkmark {
    margin-bottom: 2px;
  }

  .company-checked {
    font-size: $font-size-base * 0.875;
  }
}
