main.news {

  .article {

    &__details {
      span {
        position: relative;
        padding-right: calc(#{map_get($spacers, 2)} + 1px);
        font-family: $font-family-serif-alt;
        font-weight: $font-weight-normal;
        font-size: $font-size-p-small;
        text-transform: uppercase;
        margin-right: map_get($spacers, 2);


        &:not(:last-of-type) {
          &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            height: 13px;
            width: 1px;
            transform: translateY(-30%);
            background-color: map_get($theme-colors, "yellow");
          }
        }
      }
    }

    &__content {
      .img__caption {
        font-size: $font-size-base * 0.75;
      }
    }

    &__footer {
      hr {
        background-color: map_get($theme-colors, "yellow");
      }
    }
  }

  .news-footer {
    background-color: $newsgray;

    hr {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      border-width: 2px;
      border-color: $white;
    }

    a {
      text-decoration: none;
      img {
        width: 28px;
        height: auto;
      }
    }
  }
}