main.aboutUsOurHistoryLandingPage {
  // Desktop nav tab styling
  .nav-tabs {
    &#desktop-tabs {
      @media screen and (max-width: breakpoint-max(sm)) {
        display: none;
      }
    }

    .nav-item {
      min-width: 210px;
      color: $blue;
      background-color: $midgray;

      @media screen and (max-width: 910px) {
        min-width: 170px;
      }

      .nav-link {
        position: relative;
        border: 0;
        border-radius: 0;
        font-family: $font-family-serif-alt;

        &.active {
          background-color: $blue;
          color: $white;
          font-weight: bold;

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            height: 2px;
            width: calc(100% - #{map_get($spacers, 5)});
            background-color: $yellow;
            transform: translate(-50%, 0);
          }
        }
      }
    }

    @media screen and (max-width: breakpoint-max(sm)) {
      .nav-item {
        display: none;
      }
    }
  }

  // Mobile dropdown toggle styling
  #mobile-tab-toggle {
    width: 100%;
    font-family: $font-family-serif-alt;
    font-weight: bold;

    @media screen and (min-width: breakpoint-min(md)) {
      display: none;
    }

    &.show {
      .nav-link {
        i {
          transform: rotate(90deg);
        }
      }
    }

    .nav-link {
      i {
        transition: transform 0.3s;
      }
    }

    .dropdown-menu, .dropdown-item {
      border-radius: 0;
    }

    .dropdown-menu {
      width: 100%;
      border-bottom: 3px solid $yellow;

      // Required to override '.nav' display rules.
      &:not(.show) {
        display: none;
      }

      .dropdown-item {
        color: $blue;
        text-align: center;

        &:hover {
          background-color: inherit;
        }

        &.active {
          background-color: initial;
          font-weight: bold;
        }
      }
    }
  }

  // History item styling i.e. 1989 section
  .tab-pane__item {
    position: relative;
    background-color: $lightblue;
    color: $white;

    // Era introduction para section
    &--intro {
      &::after {
        border-top-color: $blue;
      }
    }

    &-intro-text {
      h1, h2, h3, h4, h5, h6 {
        font-family: $font-family-serif-alt;
      }

      p {
        margin-bottom: 0;
      }
    }

    &-milestone-year {
      p {
        margin-bottom: map_get($spacers, 4);
      }
    }

    &-milestone-text {
      h1, h2, h3, h4, h5, h6 {
        margin-bottom: map_get($spacers, 4);
        font-family: $font-family-serif-alt;
        font-weight: 500;
      }

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &-img-container {
      width: 100%;
      max-width: 375px;
      max-height: 375px;

      &--compat {
        img {
          opacity: 0;
        }
      }

      .tab-pane__item-img {
        width: 100%;
        max-width: 375px;
        max-height: 375px;
      }
    }

    // Triangle accents that appear below sections
    &::after {
      content: "";
      position: absolute;
      bottom: calc(-#{$section-triangle-accent-size} + 1px);
      left: 50%;

      width: 0;
      height: 0;
      border-left-width: $section-triangle-accent-size;
      border-right-width: $section-triangle-accent-size;
      border-top-width: $section-triangle-accent-size;
      border-bottom-width: 0;
      border-style: solid;
      border-left-color: transparent;
      border-right-color: transparent;

      transform: translate(-50%, 0);
      z-index: 1;
    }

    &:nth-of-type(even) {
      &::after {
        border-top-color: $lightblue;
      }

      .tab-pane__collapse-toggle {
        color: $white;

        &:hover {
          color: darken($white, 15%);
        }
      }
    }

    &:nth-of-type(odd) {
      background-color: $white;
      color: $blue;

      &:not(:first-of-type)::after {
        border-top-color: $white;
      }

      .tab-pane__collapse-toggle:hover {
        color: darken($blue, 15%);
      }

      @media screen and (min-width: breakpoint-min(md)) {
        .tab-pane__item-milestone-year, .tab-pane__item-milestone-text {
          text-align: right;
        }
      }
    }

    // Don't show section triangle accent on last section
    &:last-of-type {
      &::after {
        display: none;
      }
    }

    .tab-pane__collapse-toggle {
      &:hover {
        text-decoration: none;
        color: darken($white, 15%);
      }

      .collapse-indicator {
        transition: 0.3s;

        // If collapse is active (not '.collapsed'), rotate...
        transform: rotate(90deg);
      }

      &.collapsed {
        .collapse-indicator {
          // ...else it is collapsed, rotate back to rest.
          transform: rotate(0);
        }
      }
    }

    // Alternating img/text arrangement and styles on desktop
    @media screen and (min-width: breakpoint-min(md)) {
      &:nth-of-type(odd) {
        .tab-pane__item-col {
          order: 1;
          padding: 0 map_get($spacers, 4);

          &:first-of-type {
            order: 0;
          }
        }
      }

      &-col {
        order: 0;
        padding: 0 map_get($spacers, 4);

        &:first-of-type {
          order: 1;
        }
      }

      .collapse {
        display: block !important;
      }
    }
  }

  .era-navigator {
    position: relative;
    height: 40px;

    .era-toggle {
      font-family: $font-family-serif-alt;
      font-weight: bold;
      cursor: pointer;

      &.hide {
        visibility: hidden;
      }

      span.mob-hide {
        @media screen and (max-width: breakpoint-max(sm)) {
          display: none;
        }
      }
    }

    .divider {
      position: absolute;
      left: 50%;
      top: 50%;
      height: calc(100% - #{map_get($spacers, 3)});
      width: 2px;
      background-color: $darkgray;

      transform: translate(-50%, -50%);
    }
  }
}

// This get's applied to body when the era title sticks to the top of the viewport on mobile. Prevent's 'jump' when
// title is removed from the DOM flow.
.sticky-title-offset {
  padding-top: 52px;

  &.fixed-top {
    position: fixed !important;
  }
}