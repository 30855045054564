main.error {
  background-image: url('../img/sundries-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  @media screen and (max-width: breakpoint-max(xs)) {
    background-size: auto;
  }

  .bullet {
    height: 13px;
    width: 45px;

    &::after {
      width: 15px;
      height: 13px;
    }
  }

  h1 {
    font-size: 10rem;
    font-family: $font-family-sans-serif;
    font-weight: 300;
  }

  @media only screen and (max-width: breakpoint-max(xs)) {

    h1 {
      font-size: 8rem;
    }

    .btn {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      min-width: 260px;
    }
  }
}