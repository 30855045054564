.tag-radio-input {
  position: relative;

  /* Hide the checkbox, but still allow it to remain focusable for 
  accesibility purposes. */
  input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;
    opacity: 0;

    &:focus + label {
      outline: $btn-focus-width solid $input-btn-focus-color;
    }

    &:hover + label {
      background: map_get($theme-colors, "lightblue");
      border: 1px solid $lightblue;
    }

    &:checked + label {
      background: map_get($theme-colors, "yellow");
      color: $blue;
      border: 1px solid $yellow;
    }
  }

  label {
    cursor: pointer;
    transition: $btn-transition;
    background: map_get($theme-colors, "blue");
    border: 1px solid rgb(86, 111, 127);
    font-size: $font-size-base * 0.9;
    font-family: $font-family-serif-alt;
    font-weight: 500;
  }
}
