.modal {
  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-body {
    position: relative;

    .modal__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }
  }
}