#global-footer {
  color: $white;
  background-color: $blue;
  font-size: $small-font-size;
  border-bottom: 8px solid $yellow;

  .footer__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .footer__contact {
      span, a {
        display: block;
      }

      a {
        text-decoration: none;
      }
    }

    .tagline {
      color: $yellow;
    }

    @media screen and (max-width: (breakpoint-max(sm))) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer__nav-logo {
    max-height: 60px;

    @media screen and (max-width: breakpoint-max(sm)) {
      max-height: 100px;
    }
  }

  .footer__nav-links {
    a {
      position: relative;
      color: $white;
      padding: 0 map_get($spacers, 2);
      border-right: 1px solid $white;

      &:first-child {
        padding-left: 0;
      }

      // Prevent last child having pipe and padding
      &:last-child {
        padding-right: 0;
        border-right: 0;
      }

      &:hover {
        // 15% darken, as used by BS in $link-hover-color
        color: darken($white, 15%);
        text-decoration: none;
      }

      @media screen and (max-width: breakpoint-max(sm)) {
        &:last-child {
          padding: 0;
        }

        // Last item wraps to separate line as per designs. Remove pipe from cookie policy.
        &:not(:nth-child(-n+2)) {
          border-right: 0;
        }
      }
    }
  }
}