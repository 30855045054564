.collapsible-bio {
  position: relative;
  width: 100%;
  background-color: $gray;
  color: $blue;

  button.close {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .bio-headings {
    &__subheading {
      font-family: $font-family-serif-alt;
    }
  }

  .bio-body {
    p {
      font-size: $font-size-p-small;
    }
  }

  // Border color not specified. Defined by dynamic border color class in '_collapsible-bio.html'
  border-bottom-width: 4px;
  border-bottom-style: solid;
}