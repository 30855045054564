.slider__carousel {
  position: relative;
  background-color: $blue;

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;
        flex-grow: 1;
      }
    }
  }


  .slider-controls {
    @media screen and (max-width: breakpoint-max(md)) {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 2px;
        height: 100%;
        background-color: transparentize($white, 0.4);
        transform: translateX(-50%);
      }
    }

    .slider-control {
      position: absolute;
      top: 0;
      height: 100%;
      cursor: pointer;

      &:hover {
        svg {
          * {
            stroke: $yellow;
          }
        }
      }

      svg {
        * {
          transition: stroke 0.15s;
        }
      }

      .slick-arrow:not(.slick-hidden) {
        display: flex;
        justify-content: center;
        min-width: 25px;
      }

      @media screen and (max-width: breakpoint-max(md)) {
        position: relative;
        display: inline-block;

        svg {
          stroke-width: 5;
          height: 40px;
          width: auto;

          * {
            stroke-width: 5;
          }
        }

        &.slider-previous {
          left: map_get($spacers, 2);
        }

        &.slider-next {
          right: map_get($spacers, 2);
        }
      }

      @media screen and (min-width: breakpoint-min(lg)) {
        svg {
          stroke-width: 2;
          width: 40px;
          height: auto;

          * {
            stroke-width: 2;
          }
        }

        &.slider-previous {
          left: calc(-40px - #{map_get($spacers, 4)});
        }

        &.slider-next {
          right: calc(-40px - #{map_get($spacers, 4)});
        }
      }
    }
  }


  @media screen and (max-width: breakpoint-max(md)) {
    .slick-dotted.slick-slider {
      margin-bottom: map_get($spacers, 3);
    }
  }

  .slick-dots {
    position: relative;
    list-style: none;
    display: block;
    text-align: center;
    margin-top: map_get($spacers, 5);
    margin-bottom: map_get($spacers, 1);
    padding: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      height: 4px;
      width: 40px;
      margin: 0.75rem 0.25rem;
      padding: 0;
      cursor: pointer;

      button {
        position: absolute;
        bottom: 0;
        border: 0;
        background: $white;
        display: block;
        height: 2px;
        width: 100%;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        cursor: pointer;
        transition: 0.3s;
      }

      &.slick-active button {
        height: 6px;
        background-color: $white;
      }
    }
  }
}