$btn-accent-size: 12px;

// This mixin allows us to not only define hover colors for the button bg and text, but also for the corner accent too.
@mixin button-brand($background-color, $hover-background-color, $accent-color, $hover-accent-color, $accent-size: $btn-accent-size, $color: color-yiq($background-color), $hover-color: color-yiq($hover-background-color)) {
  color: $color !important;
  background-color: $background-color;

  &.btn-accent {
    &::after {
      border-bottom: $accent-size solid $accent-color;
    }

    &:hover:not(.no-hover) {
      &::after {
        border-bottom: $accent-size solid $hover-accent-color;
      }
    }
  }

  &:hover:not(.no-hover) {
    color: color-yiq($hover-background-color) !important;
    background-color: $hover-background-color;
  }
}

// Color contrast
@function color-yiq($color, $dark: $yiq-text-dark, $light: $yiq-text-light) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= $yiq-contrasted-threshold) {
    @return $blue;
  } @else {
    @return $light;
  }
}

.btn {
  position: relative;
  min-width: 200px;
  padding: map_get($spacers, 1) map_get($spacers, 3);
  border-radius: 0;
  border: none;
  text-transform: uppercase;
  font-family: $font-family-serif-alt;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;

  &:hover {
    @include z-depth-1-half;
  }

  &.btn-accent {
    @include z-depth-1;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-left: $btn-accent-size solid transparent;
      border-top: $btn-accent-size solid transparent;
      transition: 0.3s ease;
    }
  }

  @media screen and (max-width: breakpoint-max(xs)) {
    display: block;
    padding: map_get($spacers, 2) map_get($spacers, 5);
  }

  // SEACOR Brand buttons. Defines the various hover states and their exceptions where necessary.
  // The convention here is as follows -> .btn-{{ bg-color }}--{{ accent-color }} i.e. .btn-white--sand would
  // render a button with white background and sand coloured accent. An accent modifier with '--alt' refers to the default
  // alternative state if there is a commonly used alternative.

  @each $color, $value in $theme-colors {
    &.btn-#{$color} {
      @if $value == $white {
        // This is the default variant for .btn's with white bg
        @include button-brand($white, darken($white, 15%), $yellow, $yellow);

        &--alt {
          @include button-brand($white, darken($white, 15%), $lightblue, $lightblue);
        }

        // Hover state has a 'sand' acceent but bg remains white
        &--sand {
          @include button-brand($white,  darken($white, 15%), $sand, $sand);
        }
      }

      @elseif $value == $blue {
        // This is the default variant for .btn's with white bg
        @include button-brand($blue, darken($blue, 5%), $yellow, $yellow);

        &--alt {
          @include button-brand($blue, darken($blue, 5%), $lightblue, $lightblue);
        }
      }

      @if $value == $gray {
        // This is the default variant for .btn's with white bg
        @include button-brand($gray, darken($gray, 5%), $yellow, $yellow);
      }

      @if $value == $midgray {
        // This is the default variant for .btn's with white bg
        @include button-brand($midgray, darken($midgray, 15%), $yellow, $yellow);
      }
    }
  }

  &.btn-transparent {
    @include button-brand("transparent", $gray-300, $white, $primary, $color: $white);

    &:not(:hover, .active) {
      box-shadow: none !important;
    }
  }
}
