////////////////////////////////////////////
// Custom styling for animated menu icon //
//////////////////////////////////////////

.custom-toggler {
  position: relative;
  height: 24px;
  width: 30px;
  border: 0;
  background-color: transparent;

  // Remove outline on mobile devices only, keep accessibility intact for all other devices.
  @media screen and (max-width: breakpoint-max(xs)) {
    &:focus {
      outline: none !important;
    }
  }

  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    left: 0;
    background-color: $blue;
    border-radius: 2px;
    opacity: 1;
    transition: 0.2s;
  }

  span:first-child {
    top: 0;
  }

  span:nth-child(2), span:nth-child(3) {
    top: 50%;
    transform: translateY(-50%);
  }

  span:last-child {
    bottom: 0;
  }

  &.open {
    span:first-child {
      width: 0;
      top: 50%;
      left: 50%;
      opacity: 0;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:last-child {
      width: 0;
      bottom: 50%;
      left: 50%;
      opacity: 0;
    }
  }

  @media screen and (min-width: (breakpoint-min(lg))) {
    display: none;
  }
}