main.contact-us {
  .address-block {
    padding: 0 1rem;

    &--accent {
      display: flex;
      flex-direction: column;

      &-lightblue {
        border-left: 1px solid $lightblue;
      }

      &-yellow {
        border-left: 1px solid $yellow;
      }
    }
    
    @media only screen and (min-width: breakpoint-min("md")) {
      margin-top: map_get($spacers, 3);

      .address-title {
        height: 5.25rem;
        display: table-cell;
        vertical-align: middle;
      }
    }


    span {
      display: block;
    }

    a {
      display: block;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

    .address-hr {
      display: block;
      width: 150px;
      max-width: 75%;
      margin-left: 0;
      text-align: left;

      @media screen and (max-width: breakpoint-max(sm)) {
        margin-left: auto;
      }
    }

    .email-address, .website-url {
      // Prevent URL's from overflowing container
      word-break: break-word;
    }

    .website-url {
      margin-top: auto;
    }

    &.custom-address-block {
      p {
        margin-bottom: 0;
      }
    }
  }

  .contact-form {
    @media screen and (max-width: breakpoint-max(sm)){
      .grecaptcha-badge {
        margin: 0 auto;
      }
    }
  }
}